<template>
  <QDialog v-model="isOpen">
    <QCard style="width: 600px;">
      <CardTitle>
        {{ t('Create Restocking') }}
      </CardTitle>

      <QCardSection>
        <QSeparator />
        <BaseAlert
          v-if="getPrimaryError()"
          type="error"
        >
          {{ getPrimaryError() }}
        </BaseAlert>

        <div class="row q-col-gutter-md">
          <div class="col-6">
            <QInput
              v-model.number="minStockInDays"
              :label="t('Min Stock in Days')"
              :rules="[positiveFloatRule]"
              type="number"
              min="1"
            />
          </div>
          <div class="col-6">
            <QInput
              v-model.number="maxStockInDays"
              :label="t('Max Stock in Days')"
              :rules="[positiveFloatRule]"
              type="number"
              min="1"
            />
          </div>
        </div>

        <QSelect
          v-model="areasKindsModel"
          :label="t('Area Kind')"
          :options="[ CellsAreaKindEnum.STORAGE, CellsAreaKindEnum.EXTERNAL ]"
          :option-label="(v: CellsAreaKindEnum) => t(`cellsAreaKind.${v}`)"
        />
      </QCardSection>

      <QCardActions align="right">
        <QBtn @click="isOpen = false">
          {{ t('Cancel') }}
        </QBtn>
        <QBtn
          :disable="!canCreate()"
          :loading="progress.creating"
          color="primary"
          @click="create"
        >
          {{ t('Create') }}
        </QBtn>
      </QCardActions>
    </QCard>
  </QDialog>
  <slot
    name="activator"
    @click="isOpen = true"
  />
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { reactive, ref } from 'vue';
import useValidationRules from '@/composables/useValidationRules';
import CardTitle from '@/components/CardTitle.vue';
import BaseAlert from '@/components/BaseAlert.vue';
import { gql, useClientHandle } from '@urql/vue';
import type { MutationCreateRestockingArgs } from '@/graphql/types';
import { CellsAreaKindEnum } from '@/graphql/types';
import useErrorHandling from '@/composables/useErrorHandling';

const { t } = useI18n();

const { client: urql } = useClientHandle();

const emit = defineEmits<{
  (e: 'created'): void;
}>();

const { fillErrorsFromGraphQLError, getPrimaryError, clearErrors } = useErrorHandling();

const { positiveFloatRule } = useValidationRules();

const isOpen = ref(false);

const minStockInDays = ref<number>(1);

const maxStockInDays = ref<number>(1);

const areasKindsModel = ref<CellsAreaKindEnum>(CellsAreaKindEnum.STORAGE);

const progress = reactive({ creating: false });

const canCreate = () => {
  return minStockInDays.value > 0 && maxStockInDays.value > 0
    && maxStockInDays.value >= minStockInDays.value;
};

async function create(): Promise<void> {
  clearErrors();

  progress.creating = true;

  const { error: restockingError } = await urql.mutation<unknown, MutationCreateRestockingArgs>(
    gql`
      mutation CreateRestockingFromList(
        $stockInDaysInterval: Interval!, $cellsAreaKind: CellsAreaKindEnum!
      ) {
        createRestocking(
          stockInDaysInterval: $stockInDaysInterval,
          cellsAreaKind: $cellsAreaKind
        ) { id }
      }
    `,
    {
      stockInDaysInterval: JSON.stringify([minStockInDays.value, maxStockInDays.value]),
      cellsAreaKind:       areasKindsModel.value,
    },
  );

  if (restockingError) {
    fillErrorsFromGraphQLError(restockingError);
    progress.creating = false;
    return;
  }

  progress.creating = false;

  isOpen.value = false;

  emit('created');
}

</script>

<i18n lang="yaml">
ru:
  Create Restocking: Создать пополнение
  Min Stock in Days: Мин. остаток в днях
  Max Stock in Days: Макс. остаток в днях
  Area Kind: Тип зоны

en:
  Create Restocking: Create Restocking
  Min Stock in Days: Min Stock in Days
  Max Stock in Days: Max Stock in Days
  Area Kind: Area Kind
</i18n>
