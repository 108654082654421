<template>
  <slot :open="toggle" />
  <MaximizedDialog
    v-if="q.screen.xs"
    v-model="open"
    :title="title"
    @close="toggle"
  >
    <div class="column q-px-md q-gutter-md">
      <PrintersSelect
        hide-hardware-settings-link
        @select="selectedPrinter = $event"
      />
      <QSelect
        v-if="showPaperLayoutSelection"
        v-model="selectedPrinterPaperLayout"
        :options="paperLayouts"
        :label="t('Paper Format')"
        :display-value="paperLayoutAsString(selectedPrinterPaperLayout)"
        class="ellipsis"
        emit-value
      />
      <QSelect
        v-if="!hideBarcodeTypeSelection"
        v-model="selectedBarcodeType"
        :options="barcodeTypes"
        :option-label="barcodeTypesLabel"
        :label="t('Barcode or QR-code')"
        :display-value="barcodeTypeAsString(selectedBarcodeType)"
        class="ellipsis"
        emit-value
      />
    </div>

    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <PrintBtn
          v-bind="buttonProps"
          :disable="instances.length === 0"
          color="primary"
          @click="print"
        />
        <EnqueueBtn
          v-bind="buttonProps"
          :disable="instances.length === 0"
          @click="enqueue"
        />
      </ButtonsRow>
    </template>
  </MaximizedDialog>
  <QDialog
    v-else
    v-model="open"
  >
    <QCard :style="{minWidth: q.screen.lt.md ? undefined : '800px'}">
      <template v-if="title">
        <CardTitle>
          {{ title }}
        </CardTitle>

        <QSeparator />
      </template>
      <BaseAlert
        v-if="instances.length === 0 && noInstancesMessage"
        type="error"
      >
        {{ noInstancesMessage }}
      </BaseAlert>

      <QCardSection>
        <div class="row q-col-gutter-md">
          <div class="col-xs-6 col-md">
            <PrintersSelect @select="selectedPrinter = $event" />
          </div>
          <div
            v-if="showPaperLayoutSelection"
            class="col-xs-6 col-md"
          >
            <QSelect
              v-model="selectedPrinterPaperLayout"
              :options="paperLayouts"
              :label="t('Paper Format')"
              :display-value="paperLayoutAsString(selectedPrinterPaperLayout)"
              class="ellipsis"
              emit-value
            />
          </div>
          <div class="col-xs-6 col-md">
            <QSelect
              v-if="!hideBarcodeTypeSelection"
              v-model="selectedBarcodeType"
              :options="barcodeTypes"
              :option-label="barcodeTypesLabel"
              :label="t('Barcode or QR-code')"
              :display-value="barcodeTypeAsString(selectedBarcodeType)"
              class="ellipsis"
              emit-value
            />
          </div>
        </div>
        <div class="row q-col-gutter-md">
          <div
            v-if="!!$slots.selection"
            class="col-xs-6 col-md"
          >
            <slot name="selection" />
          </div>
          <div
            v-if="useCount"
            class="col-xs-6 col-md"
          >
            <QInput
              v-model.number="printCount"
              type="number"
              step="1"
              min="1"
              :rules="[notEmptyRule, positiveIntegerOrNullRule]"
              :label="t('Count')"
            />
          </div>
        </div>
      </QCardSection>

      <BaseAlert
        v-if="error"
        type="error"
      >
        {{ error }}
      </BaseAlert>

      <QSeparator />

      <QCardActions :vertical="q.screen.xs">
        <QBtn
          @click="toggle(false)"
        >
          {{ t('Close') }}
        </QBtn>
        <QSpace />
        <PrintBtn
          :disable="instances.length === 0"
          color="primary"
          text-as-label
          @click="print"
        />
        <EnqueueBtn
          :disable="instances.length === 0"
          color="secondary"
          icon="mdi-file-clock"
          text-as-label
          @click="enqueue"
        />
      </QCardActions>
    </QCard>
  </QDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import CardTitle from '@/components/CardTitle.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import PrintersSelect from '@/components/PrintersSelect.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import type { PrintTemplate, PrintTemplateInstance } from '@/composables/usePrintService';
import type { PrintBarcodeTypeEnum, Printer } from '@/graphql/types';
import { useToggle } from '@vueuse/core';
import { useQuasar } from 'quasar';
import { ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import useValidationRules from '@/composables/useValidationRules';

const q = useQuasar();
const { t } = useI18n();

type Template = PrintTemplate;

const props = defineProps<{
  barcodeType?: PrintBarcodeTypeEnum;
  title: string;
  templateName: Template;
  instances: PrintTemplateInstance[];
  noInstancesMessage?: string;
  useCount?: boolean;
  hideBarcodeTypeSelection?: boolean;
}>();

const emit = defineEmits<{
  (e: 'print'): void;
  (e: 'update:barcodeType', type: PrintBarcodeTypeEnum): void;
  (e: 'printer-changed', printer: Printer): void;
  (e: 'countChanged', count: number): void;
}>();

const error = ref('');

const [open, toggle] = useToggle(false);

// noinspection LocalVariableNamingConventionJS
const {
  selectedPrinter,
  paperLayoutAsString,
  paperLayouts,
  enqueuePrint,
  printNow,
  selectedPrinterPaperLayout,
  showPaperLayoutSelection,
  barcodeTypeAsString,
  barcodeTypes,
  barcodeTypesLabel,
  EnqueueBtn,
  PrintBtn,
} = useDocumentsPrinting();

const { notEmptyRule, positiveIntegerOrNullRule } = useValidationRules();

const printCount = ref(1);

watch(printCount, (v) => emit('countChanged', v));

const selectedBarcodeType = ref(props.barcodeType);

async function enqueue(): Promise<void> {
  error.value = '';
  await enqueuePrint(
    props.templateName,
    props.instances,
    selectedPrinterPaperLayout.value!,
  ).catch(e => {
    error.value = e.message;
    throw e;
  });
  toggle(false);
  emitPrint();
}

async function print(): Promise<void> {
  error.value = '';
  await printNow(
    props.templateName,
    props.instances,
    selectedPrinterPaperLayout.value!,
  ).catch(e => {
    error.value = e.message;
    throw e;
  });
  toggle(false);
  emitPrint();
}

function emitPrint(): void {
  emit('print');
}

watch(selectedBarcodeType, () => {
  emit('update:barcodeType', selectedBarcodeType.value!);
});

watch(selectedPrinter, printer => {
  emit('printer-changed', printer!);
});

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Barcode added to the print queue of {printer} printer: >
    Штрихкод добавлен в очередь печати для {printer}
  Barcode or QR-code: Штрихкод или QR-код
en:
  Barcode added to the print queue of {printer} printer: >
    Barcode added to the print queue of {printer} printer
  Barcode or QR-code: Barcode or QR-code
</i18n>
