<template>
  <QBtn
    v-bind="$attrs"
    @click="isOpen = true"
  >
    {{ t('Print settings') }}
  </QBtn>
  <div class="hidden">
    <!-- Дочерние компоненты не активируются пока не будет открыт диалог-->
    <!-- этот телепорт позволяет "активировать" нужный компонент до открытия диалога-->
    <!-- чтобы загрузить данные заранее, а не в момент открытия диалога-->
    <Teleport
      :to="teleportTo"
      :disabled="!teleportTo"
    >
      <ProductPackPrintOptions
        v-if="!weightBarcodeSelected"
        :product-pack="currentPack"
        :use-blurred-input="isOpen"
        @count-changed="printCount = $event"
      />
    </Teleport>
  </div>
  <MaximizedDialog
    v-model="isOpen"
    maximized
    transition-show="slide-up"
    transition-hide="slide-down"
    :title="t('Printing')"
    @close="isOpen = false; emit('close', selectedPrinter)"
  >
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>

      <div class="q-pa-sm text-subtitle1">
        {{ capitalize(productPackTitle(productPack)) }}
      </div>

      <QSeparator />

      <div class="q-pa-sm">
        <QSelect
          v-model="barcodeToPrint"
          :options="barcodeOptions"
          :option-label="barcodeOptionLabel"
          :label="t('Select barcode type')"
        />
      </div>
      <div class="q-pa-sm">
        <NonEditableField
          v-if="weightBarcodeSelected"
          :label="t('Quantity')"
          :error="v.weight.$error"
          :error-message="v.weight.$errors[0]?.$message"
          readonly
        >
          <div v-if="weight">
            {{ weight }}
          </div>
          <div
            v-else
            class="text-grey"
          >
            {{ t('Enter from keyboard') }}
          </div>
        </NonEditableField>
      </div>
      <div ref="teleportTo" />
      <div class="row q-pa-sm q-col-gutter-sm">
        <div class="col">
          <PrintersSelect
            hide-hardware-settings-link
            @select="selectedPrinter = $event"
          />
        </div>
        <div
          v-if="showPaperLayoutSelection"
          class="col"
        >
          <QSelect
            v-model="selectedPrinterPaperLayout"
            :options="paperLayouts"
            :label="t('Paper Format')"
            :display-value="paperLayoutAsString(selectedPrinterPaperLayout)"
            emit-value
          />
        </div>
      </div>
    </QCard>
    <template #bottom>
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <PrintBtn
          v-bind="buttonProps"
          :disable="v.$invalid"
          :print-count="printCount"
          @click="print"
        />
        <EnqueueBtn
          v-bind="buttonProps"
          :disable="v.$invalid"
          :print-count="printCount"
          @click="enqueue"
        />
      </ButtonsRow>
    </template>
    <BlurredInput
      v-if="weightBarcodeSelected"
    />
  </MaximizedDialog>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import PrintersSelect from '@/components/PrintersSelect.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import useErrorHandling from '@/composables/useErrorHandling';
import type {
  Barcode,
  Printer,
  ProductPack,

} from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import capitalize from '@/helpers/capitalize';
import productPackTitle from '@/helpers/productPackTitle';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import * as R from 'ramda';
import { computed, onMounted, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import ProductPackPrintOptions from '@/components/Mobile/ProductPackPrintOptions.vue';
import MaximizedDialog from '@/components/MaximizedDialog.vue';
import useStore from '@/stores/root';
import { Ean13Utils } from 'ean13-lib';
import useOmniInput from '@/composables/useOmniInput';
import NonEditableField from '@/components/NonEditableField.vue';
import useVuelidate from '@vuelidate/core';
import { minValue } from '@vuelidate/validators';
import { between, numeric, required, requiredIf } from '@/setup/validation';

const { t } = useI18n();

const teleportTo = ref();

const { fillErrorsFromHttpError, getPrimaryError, clearErrors } = useErrorHandling();

// noinspection LocalVariableNamingConventionJS
const {
  selectedPrinter,
  enqueuePrint,
  paperLayoutAsString,
  paperLayouts,
  showPaperLayoutSelection,
  printNow,
  selectedPrinterPaperLayout,
  loadPrinters,
  PrintBtn,
  EnqueueBtn,
} = useDocumentsPrinting();

onMounted(async () => {
  loadPrinters();
  barcodeToPrint.value = barcodeOptions.value.find(b => b.group === state.value.lastProductPackBarcodeGroup ?? null)
      ?? barcodeOptions.value[0]
      ?? null;
});

const emit = defineEmits<{
  (e: 'update', value: {
    barcode: Barcode | null;
    productPack: ProductPack;
    count: number;
  }): void;
  (e: 'close', printer: Printer | null): void;
}>();

const isOpen = ref(false);

const props = defineProps<{
  productPack: ProductPack;
}>();

const barcodeToPrint = ref<Barcode | null>( null);

const printCount = ref<number>(0);

const currentPack = computed(() => {
  if (!state.value.lastProductPackLabelCountOption) {
    return props.productPack;
  }

  return state.value.lastProductPackLabelCountOption === t('By supply')
    ? props.productPack.product.mostBasicProductPack
    : props.productPack;
});

const state = useDocumentsPrintingState();

function barcodeOptionLabel(barcode: Barcode) {
  return (barcode.group ? `${barcode.group}` : t('Primary'));
}

const weight = ref('');

const weightBarcodeSelected = computed(() =>
  isSkuForBarcodeWithAmount.value && barcodeToPrint.value?.group === t('Weight Type')
);

const isSkuForBarcodeWithAmount = computed(() => /^\d{5}$/.test(props.productPack.product.sku));

watch(weightBarcodeSelected, v => {
  if (v) {
    printCount.value = 1;
  }
});

const v = useVuelidate({
  weight:     {
    required: requiredIf(() => currentPack.value.measurementUnit.isFractional),
    numeric,
    between: between(0, 100),
  },
  printCount: {
    minValue: minValue(1),
  },
  barcodeToPrint: {
    required
  }
}, { weight, printCount, barcodeToPrint }, { $autoDirty: true });

const barcodeOptions = computed(function () {
  const barcodes = [] as Barcode[];
  const prefix = useStore().warehouse!.weightBarcodesPrefix;

  if (currentPack.value.measurementUnit.isFractional) {
    if (isSkuForBarcodeWithAmount.value && prefix) {
      const weightStr = v.value.weight.$invalid
        ? '00000'
        : Number.parseFloat(weight.value).toFixed(3).replace('.', '').padStart(5, '0');
      const weightBarcode = `${prefix}${props.productPack.product.sku}${weightStr}`;

      barcodes.push({
        __typename:  'Barcode',
        barcode:     weightBarcode + Ean13Utils.calculateCheckDigit(weightBarcode),
        group:       t('Weight Type'),
        isGenerated: false,
      } as Barcode);
    }
  }
  else {
    barcodes.push(...currentPack.value.barcodes);
  }

  return R.pipe(
    R.groupBy(b => (b as Barcode).group || ''),
    R.values,
    R.map(values => R.sortBy(b => (b as Barcode).barcode, (values as Barcode[]))[0]),
  )(barcodes);
});

const input = useOmniInput({
  skip: computed(() => !weightBarcodeSelected.value),
  replace: value => value.replace(/,/g, '.').replace(/[^\d.]/g, ''),
});
const { BlurredInput, value: inputValue } = input;

watch(inputValue, value => {
  if (!input.isPristine.value && !Number.isNaN(Number(value))) {
    weight.value = value;
  }
});

watch(() => currentPack.value.barcodes, () => {
  barcodeToPrint.value = barcodeOptions.value.find(
    b => b.group === barcodeToPrint.value?.group,
  ) ?? null;
});

watch(weight, () => {
  if (weightBarcodeSelected.value) {
    barcodeToPrint.value = barcodeOptions.value[0] ?? null;
  }
});

watch(barcodeToPrint, (value: Barcode|null) => {
  state.value.lastProductPackBarcodeGroup = value?.group ?? null;
});

watch(() => state.value.lastProductPackLabelCountOption, () => {
  barcodeToPrint.value = barcodeOptions.value.find(
    b => b.group === state.value.lastProductPackBarcodeGroup ?? null,
  ) ?? null;
});

watch([printCount, barcodeToPrint], ([printCount, barcodeToPrint]) => {
  emit('update', { barcode: barcodeToPrint, productPack: currentPack.value, count: printCount });
});

async function enqueue(): Promise<void> {
  clearErrors();
  try {
    await enqueuePrint('product-pack-barcode', [{
      count: printCount.value,
      params: {
        packId:      props.productPack.id,
        barcode:     barcodeToPrint.value!.barcode,
        barcodeType: state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE,
      }
    }], selectedPrinterPaperLayout.value!);
  } catch (e) {
    fillErrorsFromHttpError(e);
    return;
  }

  isOpen.value = false;
}

async function print(): Promise<void> {
  clearErrors();
  try {
    await printNow('product-pack-barcode', [{
      count: printCount.value,
      params: {
        packId:      props.productPack.id,
        barcode:     barcodeToPrint.value!.barcode,
        barcodeType: state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE,
      }
    }], selectedPrinterPaperLayout.value!);
  } catch (e) {
    fillErrorsFromHttpError(e);
    return;
  }

  isOpen.value = false;
}

</script>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Primary: Основной
  Select barcode type: Выберите тип ШК

en:
  Primary: Primary
  Select barcode type: Select barcode type
</i18n>
