<template>
  <QDrawer
    v-if="user"
    v-model="isOpen"
    behavior="mobile"
  >
    <QList>
      <QExpansionItem>
        <template #header>
          <QItemSection avatar>
            <QAvatar>
              <QImg :src="user.profilePhotoUrl" />
            </QAvatar>
          </QItemSection>
          <QItemSection>
            <QItemLabel>{{ userFullName(user) }}</QItemLabel>
            <QItemLabel caption>
              {{ user.email ?? user.username }}
            </QItemLabel>
          </QItemSection>
        </template>

        <QList>
          <QItem
            color="primary"
            tag="label"
          >
            <QItemSection avatar>
              <QAvatar icon="mdi-package-variant" />
            </QItemSection>
            <QItemSection>
              {{ t('Terminal Mode') }}
            </QItemSection>
            <QItemSection side>
              <QToggle v-model="preferences.terminalMode" />
            </QItemSection>
          </QItem>
          <QItem :to="{ name: ROUTES.PROFILE }">
            <QItemSection avatar>
              <QAvatar icon="mdi-account" />
            </QItemSection>
            <QItemSection>{{ t('Profile') }}</QItemSection>
          </QItem>
          <QItem
            v-if="canViewApiTokens && !preferences.terminalMode"
            :to="{ name: ROUTES.API_TOKENS_LIST }"
          >
            <QItemSection avatar>
              <QAvatar icon="mdi-key-chain" />
            </QItemSection>
            <QItemSection>{{ t('API Tokens') }}</QItemSection>
          </QItem>
          <QItem
            clickable
            @click="emit('logout')"
          >
            <QItemSection avatar>
              <QAvatar icon="mdi-logout" />
            </QItemSection>
            <QItemSection>{{ t('Logout') }}</QItemSection>
          </QItem>
        </QList>
      </QExpansionItem>
    </QList>

    <QSeparator />

    <QList>
      <NavigationItem :route="ROUTES.DASHBOARD" />
      <NavigationItemsGroup
        :title="t('Products Incomes')"
        icon="mdi-package-variant"
        :children="[
          ROUTES.SUPPLIES_LIST,
          ROUTES.ARRIVAL_DASHBOARD,
          ROUTES.ARRIVALS_LIST,
          ROUTES.CUSTOMER_RETURNS_LIST,
        ]"
      />
      <NavigationItemsGroup
        :title="t('Products Storage')"
        icon="mdi-warehouse"
        :children="[
          ROUTES.INVENTORY_DASHBOARD,
          ROUTES.TRANSFER_DASHBOARD,
          ROUTES.TRANSFERS_LIST,
        ]"
      />
      <NavigationItemsGroup
        :title="t('Shipping')"
        icon="mdi-truck"
        :children="[
          ROUTES.CUSTOMER_ORDERS_LIST,
          ROUTES.SUPPLIER_RETURNS_LIST,
          ROUTES.SELECTION_DASHBOARD,
          ROUTES.SELECTIONS_LIST,
          ROUTES.REALIZATIONS_LIST,
        ]"
      />
      <NavigationItemsGroup
        :title="t('Reports')"
        icon="mdi-file-chart"
        :children="[
          ROUTES.STOCKS_LIST,
          ROUTES.PRODUCTS_MOVEMENTS_LIST,
          ROUTES.OPERATIONS_LIST,
        ]"
      />
      <NavigationItemsGroup
        :title="t('Directories')"
        icon="mdi-printer"
        :children="[
          ROUTES.CELLS_AREAS_LIST,
          ROUTES.CELLS_LIST,
          ROUTES.RACKS_LIST,
          ROUTES.PRODUCTS_LIST,
          ROUTES.CONTAINERS_LIST,
          ROUTES.USERS_LIST,
        ]"
      />
      <NavigationItemsGroup
        :title="t('Administration')"
        icon="mdi-shield-crown"
        :children="[
          ROUTES.GLOBAL_ACCOUNTS_LIST,
          ROUTES.GLOBAL_ACCOUNTS_PAYMENTS_LIST,
        ]"
      />
      <NavigationItem :route="ROUTES.WAREHOUSE_SETTINGS" />

      <QSeparator />

      <QItem
        clickable
        @click="isOpen = false"
      >
        <QItemSection avatar>
          <QAvatar icon="mdi-arrow-left" />
        </QItemSection>
        <QItemSection>
          {{ t('Close') }}
        </QItemSection>
      </QItem>
    </QList>
  </QDrawer>
</template>

<script setup lang="ts">

import useCan from '@/composables/useCan';
import useLocalPreferences from '@/composables/useLocalPreferences';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import useStore from '@/stores/root';
import { Ability } from '@/types/backend';
import NavigationItem from '@/views/Navigation/NavigationItem.vue';
import NavigationItemsGroup from '@/views/Navigation/NavigationItemsGroup.vue';
import { useVModel } from '@vueuse/core';
import { QAvatar, QExpansionItem, QItem, QItemSection } from 'quasar';
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const store = useStore();

const user = computed(() => store.user!);

const preferences = useLocalPreferences();

const props = defineProps<{
  modelValue: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', flag: boolean): void;
  (e: 'logout'): void;
}>();

const isOpen = useVModel(props);

const canViewApiTokens = useCan(Ability.ViewApiTokens);

</script>

<i18n lang="yaml" src="../plugins/i18n/sharedMessages/navigation.yaml"></i18n>

<i18n lang="yaml">
ru:
  Terminal Mode: Режим ТСД
  Profile: Профиль
  API Tokens: API токены
  Logout: Выйти

en:
  Terminal Mode: Terminal Mode
  Profile: Profile
  API Tokens: API Tokens
  Logout: Logout
</i18n>
