<template>
  <GraphQLReportList
    v-bind="$attrs"
    :graphql-query="query"
    :fixed-filters="fixedFilters"
    :no-data-label="t('No Transfers')"
    :storage-prefix="storagePrefix"
  >
    <template #item="{ item }">
      <TransferListItem
        :transfer="item"
        clickable
        @click="handleItemClick(item)"
      />
    </template>
  </GraphQLReportList>
  <TransferTaskDialog
    v-if="transferWithTask"
    :transfer="transferWithTask"
    :model-value="!!transferWithTask"
    @update:model-value="transferWithTask = null"
  >
    <template #buttons="{ buttonProps }">
      <QBtn
        v-bind="buttonProps"
        icon="mdi-play"
        :disable="!canStartTask"
        @click="startTransfer(transferWithTask!)"
      >
        {{ transferWithTask!.state === TransferStateEnum.CREATED ? t('Start') : t('Continue') }}
      </QBtn>
    </template>
  </TransferTaskDialog>
</template>

<script setup lang="ts">

import GraphQLReportList from '@/components/Mobile/GraphQLReportList.vue';
import TransferForProcess from '@/graphql/fragments/TransferForProcess';
import { type ReportFilterInput, type Transfer, TransferStateEnum } from '@/graphql/types';
import ROUTES from '@/router/routeNames';
import useTransferProcessStore from '@/stores/transferProcess';
import TransferListItem from '@/views/Mobile/Transfer/TransferListItem.vue';
import TransferTaskDialog from '@/views/Mobile/Transfer/TransferTaskDialog.vue';
import { gql } from '@urql/vue';
import { computed, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';

const { t } = useI18n();

const props = defineProps<{
  states: TransferStateEnum[];
  storagePrefix: string;
}>();

const router = useRouter();

const store = useTransferProcessStore();

const query = gql`
  query TransfersForDashboard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: transfersList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node { ...TransferForProcess }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }

  ${TransferForProcess}
`;

const fixedFilters = computed<ReportFilterInput[]>(() => [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify(props.states),
}]);

const transferWithTask = ref<Transfer | null>(null);

function handleItemClick(item: Transfer): void {
  if (props.states.includes(TransferStateEnum.COMPLETED)) {
    router.push({ name: ROUTES.TRANSFERS_EDIT, params: { id: item.id } });
    return;
  }

  if (item.task) {
    transferWithTask.value = item;
    return;
  }

  startTransfer(item);
}

function startTransfer(transfer: Transfer) {
  store.transfer = null;
  store.storageFrom = null;
  store.storageTo = null;

  router.push({ name: ROUTES.TRANSFER_PROCESS, params: { transferId: transfer.id } });
}

const canStartTask = computed(() => transferWithTask.value!.task!.items.some((i) => i.plannedAmount > i.transferredAmount));

</script>

<i18n lang="yaml">
ru:
  No Transfers: Перемещения отсутствуют

en:
  No Transfers: No Transfers

</i18n>
