<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      storage-prefix="arrivalsList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.ARRIVALS_EDIT, params: { id: column.value } }"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QSkeleton v-if="column.loading" />
          <QChip
            v-else
            :color="badgeColors.forOperationState(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Arrival,
  CustomerReturn,
  ReportFilterInput,
  SupplierDelivery,
  Supply,
} from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import { createBoolean, createDatesRangeOperator, createPrimitive } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import type { ReportFilter, TableColumn } from '@/types/reports';
import { gql } from '@urql/vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

useBreadcrumbs([ t('Products Incomes'), t('Arrivals list') ]);

const tableFields: TableColumn<Arrival>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    align:    'left',
    format:   d => formatDate(d, FORMATS.DATETIME),
    sortable: true,
  },
  {
    label:   t('State'),
    name:    'state',
    field:   'state',
    align:   'left',
    format: (state) => t(`arrivalState.${state}`),
    sortable: true,
  },
  {
    label: t('User'),
    name:  'user',
    field: 'user',
    format: (user) => userFullName(user),
    align: 'left',
  },
  {
    label:  t('Sup / Ret Number'),
    name:   'supply',
    field:  'supply',
    format: (supply?: Supply) => supply?.externalId ?? '',
  },
  {
    label:  t('Type'),
    name:   'type',
    field:  'supply',
    format: (supply?: CustomerReturn | SupplierDelivery) => supply?.__typename === 'CustomerReturn'
      ? t('Customer Return')
      : t('Supply'),
  },
];

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    field: 'supply',
    label: t('Supply'),
    operators: [
      createPrimitive('=', 'string'),
      createBoolean('is null', t('Empty')),
    ],
  },
  {
    field:     'productSku',
    label:     t('SKU'),
    operators: [
      createPrimitive('=', 'string'),
      createPrimitive('in', 'multiString', {
        label: t('reportFilterOperator.in'),
      }),
    ],
  },
];

const query = gql`
  query GetOperations(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
      result: operationsReport(
        page: $page,
        perPage: $perPage,
        query: $query,
        filter: $filter,
        sort: $sort,
      ) {
        data {
          __typename
          id
          createdAt
          ... on Arrival {
            state
            supply { __typename id externalId }
          }
          user { id firstname lastname }
        }
        total
      }
    }
`;

const fixedFilters: ReportFilterInput[] = [{
  field:    'type',
  operator: '=',
  value:    JSON.stringify('Arrival' as Arrival['__typename']),
}];

</script>

<i18n lang="yaml">
ru:
  Arrivals list: Приёмки
  Products Incomes: Приём товара
  Sup / Ret Number: № Пост / Возв

en:
  Arrivals list: Arrivals List
  Products Incomes: Products Incomes
  Sup / Ret Number: Sup / Ret Number
</i18n>
