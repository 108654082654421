<template>
  <ShipmentEditMobile
    v-if="preferences.terminalMode"
    :id="id"
    @close="navigateBack({ name: ROUTES.SUPPLIER_RETURNS_LIST })"
  />
  <ShipmentEditDesktop
    v-else
    :id="id"
  >
    <template #title="{ shipment }: { shipment: SupplierReturn }">
      {{ t('Supplier Return {id}', { id: shipment.externalId }) }}
    </template>
    <template #fields="{ shipment }: { shipment: SupplierReturn }">
      <div class="col-12 col-sm-6 col-md-3 col-lg-2">
        <QInput
          readonly
          :label="t('Carrier Name')"
          stack-label
          :placeholder="t('Not specified')"
          :model-value="shipment.carrier?.name"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-3 col-lg-1">
        <QInput
          readonly
          :label="t('Planned Shipment Date')"
          :model-value="formatDate(shipment.plannedShipmentDate)"
          stack-label
          :placeholder="t('Not specified')"
        />
      </div>
      <div
        v-if="shipment.counterparty"
        class="col-12 col-sm-6 col-md-4 col-lg-2"
      >
        <QInput
          readonly
          :label="t('Supplier')"
          :model-value="shipment.counterparty.name"
        />
      </div>
      <div class="col-12 col-sm-6 col-md-4 col-lg-2">
        <QInput
          readonly
          :label="t('Supply Number')"
          :model-value="shipment.supplyNumber || shipment.supply?.externalId"
          stack-label
        />
      </div>
    </template>
    <template #not-found-banner>
      <SupplierReturnNotFoundBanner />
    </template>
    <template #complete-button="{shipment, onCompleted}">
      <ShipmentCompleteDialog
        :shipment="shipment"
        :title="t('Complete Return?')"
        :label="t('Complete Return')"
        @completed="onCompleted"
      />
    </template>
  </ShipmentEditDesktop>
</template>

<script setup lang="ts">

import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import ShipmentEditMobile from '@/views/Shipping/Shipments/ShipmentEditMobile.vue';
import ShipmentEditDesktop from '@/views/Shipping/Shipments/ShipmentEditDesktop.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import type { Scalars, SupplierReturn } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import useBreadcrumbs from '@/composables/useBreadcrumbs';
import type { Component } from 'vue';
import { h } from 'vue';
import NotFoundBanner from '@/components/NotFoundBanner.vue';
import ROUTES from '@/router/routeNames';
import navigateBack from '@/helpers/navigateBack';
import ShipmentCompleteDialog from '@/views/Shipping/Shipments/ShipmentCompleteDialog.vue';

const preferences = useLocalPreferences();

const { formatDate } = useLocalizedFormatters();

const { t } = useI18n();

useBreadcrumbs([t('Supplier Return')]);

defineProps<{
  id: Scalars['ID'];
}>();

const SupplierReturnNotFoundBanner: Component = () => h(NotFoundBanner, {
  notFoundMessage: t('Supplier Return not found'),
  backRoute:       { name: ROUTES.SUPPLIER_RETURNS_LIST },
  goBackMessage:   t('Go to Supplier Returns List'),
});

</script>

<i18n lang="yaml">
ru:
  Supplier Return not found: Возврат поставщику не найден
  Go to Supplier Returns List: Перейти к списку возвратов поставщику
  Supplier Return {id}: Возврат поставщику {id}

  Complete Return?: Завершить возврат?
  Complete Return: Завершить возврат

en:
  Supplier Return not found: Supplier Return not found
  Go to Supplier Returns List: Go to Orders List
  "Supplier Return {id}": Supplier Return {id}

  Complete Return?: Complete Return?
  Complete Return: Complete Return
</i18n>
