<template>
  <QPage padding>
    <QCard>
      <BaseAlert
        v-if="getPrimaryError()"
        type="error"
      >
        {{ getPrimaryError() }}
      </BaseAlert>
      <QCardSection
        v-if="fetching"
        class="text-center"
      >
        <BigLoading />
      </QCardSection>
      <template v-else-if="arrival">
        <CardTitle>
          {{ t('Arrival') }}
          {{
            t('{id} dated {date}', {
              id: id,
              date: formatDate(arrival.createdAt, FORMATS.DATETIME),
            })
          }}
          <OperationState :operation="arrival" />
        </CardTitle>

        <QCardSection>
          <div class="row q-col-gutter-sm">
            <div class="col-sm-4 col-md-3">
              <NonEditableField
                :label="t('User')"
              >
                {{ userFullName(arrival.user) }}
              </NonEditableField>
            </div>
            <div class="col-sm-4 col-md-3">
              <LinkField
                v-if="arrival.supply"
                :label="t('Supply')"
                :to="{ name: ROUTES.SUPPLIES_EDIT, params: { id: arrival.supply.id } }"
              >
                {{ t('{id} dated {date}', {
                  id: arrival.supply.externalId,
                  date: formatDate(arrival.supply.createdAt),
                }) }}
              </LinkField>
              <NonEditableField
                v-else
                :label="t('Supply')"
              >
                {{ t('Empty') }}
              </NonEditableField>
            </div>
            <div class="col-sm-4 col-md-3">
              <NonEditableField :label="isCustomerReturn ? t('Customer') : t('Supplier')">
                {{ arrival.supply.counterparty?.name || t('Empty') }}
              </NonEditableField>
            </div>
            <div class="col-sm-4 col-md-3">
              <NonEditableField
                :label="t('Cell')"
              >
                {{ arrival.storage.name }}
              </NonEditableField>
            </div>
          </div>
        </QCardSection>

        <FilterableTable
          :rows="arrivalTableItems"
          :fields="movementsTableHeaders"
          :storage-prefix="'arrivalCard.' + arrival.id"
          show-line-numbers
          :item-meets-search-string="movementMeetsSearchString"
          hide-column-settings
        >
          <template #body-cell-productSku="scope">
            <BodyCellLink
              :column="scope"
              :to="{ name: ROUTES.PRODUCTS_EDIT, params: { id: scope.row.storable.productPack.product.id }}"
              variant="link"
            />
          </template>
        </FilterableTable>

        <QSeparator />

        <QCardActions>
          <QBtn
            exact
            icon="mdi-arrow-left"
            @click="navigateBack({name: ROUTES.ARRIVALS_LIST})"
          >
            {{ t('Back') }}
          </QBtn>

          <QSpace />

          <ConfirmsAction
            :confirm-text="t('Delete')"
            @confirmed="deleteArrival"
          >
            <template #title>
              {{ t('Delete Arrival?') }}
            </template>
            <template #activator="{ prompt }">
              <QBtn
                color="red"
                icon="mdi-delete"
                :label="t('Delete')"
                :loading="deleting"
                @click="prompt"
              />
            </template>
          </ConfirmsAction>
        </QCardActions>
      </template>
      <BaseAlert
        v-else
        type="info"
        icon="mdi-run-fast"
      >
        <p>
          {{ t('Arrival not found') }}
        </p>
        <QBtn
          exact
          color="primary"
          icon="mdi-arrow-left"
          :to="{ name: ROUTES.ARRIVALS_LIST }"
        >
          {{ t('Go to Arrivals List') }}
        </QBtn>
      </BaseAlert>
    </QCard>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import BigLoading from '@/components/BigLoading.vue';
import CardTitle from '@/components/CardTitle.vue';
import LinkField from '@/components/LinkField.vue';
import NonEditableField from '@/components/NonEditableField.vue';
import OperationState from '@/components/OperationState.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import type {
  Arrival,
  CustomerReturn,
  Movement,
  MutationDeleteArrivalArgs,
  SupplierDelivery,
} from '@/graphql/types';
import userFullName from '@/helpers/userFullName';
import type { TableColumn } from '@/types/reports';
import { gql, useMutation, useQuery } from '@urql/vue';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { collectBy } from 'ramda';
import ConfirmsAction from '@/components/ConfirmsAction.vue';
import navigateBack from '@/helpers/navigateBack';
import FilterableTable from '@/components/FilterableTable.vue';
import movementMeetsSearchString from '@/helpers/movementMeetsSearchString';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();

const props = defineProps<{
  id: string;
}>();

const { data, error, fetching } = useQuery<{ arrival: Arrival }>({
  query: gql`
    query GetArrivalForCard($id: ID!) { arrival(id: $id) {
      id
      createdAt
      state
      user { id firstname lastname }
      supply {
        id
        createdAt
        externalId
        counterparty { id name }
      }
      storage { id name }
      movements {
        id
        amount
        storable {
          id
          batch {
            id
            name
            product {
              id
              accountingModel { id batchDisplayFormat }
            }
          }
          productPack {
            id
            product { id sku name }
            barcodes { barcode }
            measurementUnit { id name shortName }
            quantityInMinMeasurementUnits
          }
        }
      }
    } }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);
const arrival = computed(() => data.value?.arrival);

const movementsTableHeaders: TableColumn<Movement>[] = [
  {
    label: t('SKU'),
    name:  'productSku',
    field: m => m.storable.productPack.product.sku,
    align: 'left',
  },
  {
    label: t('Name'),
    name:  'productName',
    field: m => m.storable.productPack.product.name,
    align: 'left',
  },
  {
    label: t('Batch'),
    name:  'batchName',
    field: m => m.storable.batch.product.accountingModel.batchDisplayFormat
      ? m.storable.batch.name
      : '',
    align: 'left',
  },
  {
    label:      t('Amount'),
    labelTitle: t('Amount in Base Units'),
    name:       'amountInBaseUnits',
    field:      'amount',
  },
];

const arrivalTableItems = computed(() => collectBy(
  movement => movement.storable.batch.id,
  arrival.value?.movements ?? [],
).map(ms => ({
  ...ms[0],
  amount: ms.reduce((acc, m) => acc + m.amount * m.storable.productPack.quantityInMinMeasurementUnits, 0),
})));

const deleting = ref(false);

const { executeMutation: doDeleteArrival } = useMutation<unknown, MutationDeleteArrivalArgs>(
  gql`
    mutation DeleteArrivalFromCard($arrivalId: ID!) {
      deleteArrival(arrivalId: $arrivalId)
    }
  `,
);

async function deleteArrival() {
  deleting.value = true;

  const { error } = await doDeleteArrival({
    arrivalId: props.id,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
  } else {
    navigateBack({ name: ROUTES.ARRIVALS_LIST });
  }

  deleting.value = false;
}

const isCustomerReturn = computed(() => (arrival.value?.supply as CustomerReturn | SupplierDelivery)?.__typename === 'CustomerReturn');

</script>

<i18n lang="yaml">
ru:
  Arrival not found: Приёмка не найдена
  Products Incomes: Приём товара
  Arrivals list: Приёмки
  Arrival: Приёмка
  Pack: Упаковка
  Packs Amount: Кол-во упаковок
  Go to Arrivals List: К приёмкам
  Delete Arrival?: Удалить приемку?

en:
  Arrival not found: Arrival not found
  Products Incomes: Products Incomes
  Arrivals list: Arrivals list
  Arrival: Arrival
  Pack: Pack
  Packs Amount: Packs Amount
  Go to Arrivals List: Go to Arrivals List
  Delete Arrival?: Delete Arrival?
</i18n>
