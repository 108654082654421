<template>
  <QPage padding>
    <BaseAlert
      v-if="getPrimaryError()"
      type="error"
    >
      {{ getPrimaryError() }}
    </BaseAlert>
    <BigLoading v-if="fetching" />
    <template v-else-if="inventory">
      <InventoryEditDesktop
        v-if="!preferences.terminalMode"
        :inventory="inventory"
      />
      <InventoryShow
        v-else
        :inventory="inventory"
      />
    </template>
    <BaseAlert
      v-else
      type="info"
      icon="mdi-run-fast"
    >
      <p>
        {{ t('Inventory not found') }}
      </p>
      <QBtn
        exact
        color="primary"
        icon="mdi-arrow-left"
        :to="{ name: ROUTES.OPERATIONS_LIST }"
      >
        {{ t('Go to Operations List') }}
      </QBtn>
    </BaseAlert>
  </QPage>
</template>

<script setup lang="ts">

import BaseAlert from '@/components/BaseAlert.vue';
import useErrorHandling from '@/composables/useErrorHandling';
import InventoryForProcess from '@/graphql/fragments/InventoryForProcess';
import type { Inventory, } from '@/graphql/types';
import { useI18n } from 'vue-i18n';
import { gql, useQuery } from '@urql/vue';
import { computed, watch } from 'vue';
import BigLoading from '@/components/BigLoading.vue';
import InventoryEditDesktop from '@/views/Storage/InventoryEditDesktop.vue';
import InventoryShow from '@/views/Mobile/Inventory/InventoryShow.vue';
import useLocalPreferences from '@/composables/useLocalPreferences';
import ROUTES from '@/router/routeNames';

const { t } = useI18n();

const preferences = useLocalPreferences();

const { fillErrorsFromGraphQLError, getPrimaryError } = useErrorHandling();

const props = defineProps<{
  id: string;
}>();

const { data, error, fetching } = useQuery<{ inventory: Inventory }>({
  query:     gql`
    query GetInventory($id: ID!) { inventory(id: $id) {
      ...InventoryForProcess
      items {
        storable {
          minMeasurementUnit { id name shortName }
        }
      }
      movements {
        storable {
          productPack {
            minMeasurementUnit { id name shortName }
          }
        }
      }
      supply {
        externalId
        createdAt
      }
    } }

    ${ InventoryForProcess }
  `,
  variables: computed(() => ({
    id: props.id,
  })),
});
watch(error, fillErrorsFromGraphQLError);
const inventory = computed(() => data.value?.inventory);

</script>

<i18n lang="yaml">
ru:
  Inventory not found: Инвентаризация не найдена
  Go to Operations List: К списку операций

en:
  Inventory not found: Inventory not found
  Go to Operations List: Go to Operations List
</i18n>
