<template>
  <GraphQLSelect
    v-model="supply"
    clearable
    :query="suppliesQuery"
    :filters="suppliesFilters"
    :limit="SUPPLIES_LIMIT"
    option-value="id"
    :display-value="t('Not specified')"
    :hint="t('To correct arrived amount')"
  >
    <template #option="{ opt, itemProps }">
      <QItem v-bind="itemProps">
        <QItemSection>
          <QItemLabel>
            <ReturnableDocumentLabel :document="opt" />
          </QItemLabel>
          <QItemLabel caption>
            {{ formatDate(opt.createdAt) }}
          </QItemLabel>
        </QItemSection>
        <QItemSection side>
          <QChip
            :color="forSupplyState(opt)"
            text-color="black"
          >
            {{ t('supplyState.' + opt.state) }}
          </QChip>
        </QItemSection>
      </QItem>
    </template>
    <template
      v-if="supply"
      #selected-item
    >
      <ReturnableDocumentLabel :document="supply" />
    </template>
  </GraphQLSelect>
</template>

<script setup lang="ts">

import { useI18n } from 'vue-i18n';
import { gql } from '@urql/vue';
import type { ReportFilterInput, Supply } from '@/graphql/types';
import { SupplyStateEnum } from '@/graphql/types';
import ReturnableDocumentLabel from '@/components/ReturnableDocumentLabel.vue';
import GraphQLSelect from '@/components/Mobile/GraphQLSelect.vue';
import { useVModel } from '@vueuse/core';
import { forSupplyState } from '@/helpers/badgeColors';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';

const { t } = useI18n();

const SUPPLIES_LIMIT = 20;

const { formatDate } = useLocalizedFormatters();

const props = defineProps<{
  modelValue: Supply | null;
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', supply: Supply | null): void;
}>();

const supply = useVModel(props, 'modelValue', emit);

const suppliesQuery = gql`
  query GetSuppliesForCorrectionOnInventoryCard(
    $first: Int!,
    $after: Cursor,
    $query: String,
    $filter: [ReportFilterInput!],
  ) {
    result: suppliesList(
      first: $first,
      after: $after,
      query: $query,
      filter: $filter,
    ) {
      edges {
        cursor
        node {
          __typename
          id
          externalId
          createdAt
          state
        }
      }
      pageInfo {
        hasPreviousPage
        hasNextPage
        startCursor
        endCursor
      }
    }
  }
`;

const suppliesFilters: ReportFilterInput[] = [{
  field:    'state',
  operator: 'in',
  value:    JSON.stringify([
    SupplyStateEnum.ARRIVING,
  ]),
}];

</script>

<i18n lang="yaml">
ru:
  To correct arrived amount: Для корректировки принятого количества

en:
  To correct arrived amount: To correct arrived amount
</i18n>
