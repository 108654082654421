<template>
  <QCard>
    <PrimaryErrorBanner />
    <QList v-if="!hideDetails">
      <QItem>
        <QItemSection>
          <QItemLabel caption>
            {{ product.sku }}
          </QItemLabel>
          <QItemLabel>
            {{ product.name }}
          </QItemLabel>
        </QItemSection>
      </QItem>

      <QSeparator />
    </QList>
    <QCardSection
      v-if="!hideDetails"
      class="text-subtitle1"
    >
      {{ capitalize(productPackTitle(productPack)) }}
    </QCardSection>

    <QCardSection>
      <div class="row q-col-gutter-sm">
        <div class="col">
          <ProductPackEditDimensions
            :product-pack="productPack"
            @saved="handleDimensionsSaved"
          >
            <template #activator="{ onClick }">
              <QBtn
                flat
                round
                icon="mdi-pencil"
                @click="onClick"
              />
            </template>
          </ProductPackEditDimensions>
        </div>
        <div class="col-6">
          <NonEditableField :label="t('Dimensions')">
            {{ productPack.dimensions ? formatDimensions(productPack.dimensions) : t('Not specified') }}
          </NonEditableField>
        </div>

        <div class="col-4">
          <NonEditableField :label="t('Weight')">
            {{ productPack.weight ? formatWeight(productPack.weight) : t('Not specified') }}
          </NonEditableField>
        </div>
      </div>
      <div>
        <QChip
          v-for="barcode in sortedBarcodes"
          :key="barcode.barcode"
          :icon="barcode.isGenerated ? 'mdi-dice-3-outline' : undefined"
          clickable
          size="12px"
          class="q-pr-xs"
          color="grey-4"
          text-color="black"
          @click="setAsLast(barcode)"
        >
          {{ barcode.group ? `${barcode.group}: ` : '' }}{{ barcode.barcode }}
          <ConfirmsAction
            @confirmed="deleteBarcode(barcode)"
          >
            <template #activator="{ prompt }">
              <QSpace />
              <QBtn
                icon="mdi-close-circle"
                flat
                size="sm"
                dense
                class="text-grey"
                :loading="deletingBarcodes.has(barcode.barcode)"
                @click.stop="prompt()"
              />
            </template>

            <template #title>
              {{ t('Delete Barcode?') }}
            </template>
          </ConfirmsAction>
        </QChip>
        <QInnerLoading :showing="changingLastBarcode" />
      </div>
    </QCardSection>

    <Teleport
      :to="buttonsPanel"
      :disabled="!buttonsPanel"
    >
      <ButtonsRow
        v-slot="{ buttonProps }"
        v2
      >
        <slot
          name="left-buttons"
          :button-props="buttonProps"
        />
        <ProductPackEditNewBarcode
          #="{ onOpen }"
          :product-pack="productPack"
          @save="emit('save', {
            ...productPack,
            ...$event,
          })"
        >
          <QBtn
            v-bind="buttonProps"
            icon="mdi-barcode-scan"
            @click="onOpen"
          >
            {{ t('New Barcode') }}
          </QBtn>
        </ProductPackEditNewBarcode>
        <PrintBtn
          v-bind="buttonProps"
          :disable="!barcodeToPrint || countToPrint === 0"
          :print-count="countToPrint"
          @click="print"
        />
        <EnqueueBtn
          v-bind="buttonProps"
          :disable="!barcodeToPrint || countToPrint === 0"
          :print-count="countToPrint"
          @click="enqueue"
        />
        <ProductPackPrint
          v-bind="buttonProps"
          icon="mdi-cog"
          :product-pack="productPack"
          @update="handleDataToPrintUpdate"
          @close="selectedPrinter = $event"
        />
      </ButtonsRow>
    </Teleport>

    <div v-if="!hidePhoto">
      <ProductPhoto :product="product" />
    </div>
  </QCard>
</template>

<script setup lang="ts">

import ButtonsRow from '@/components/Mobile/ButtonsRow.vue';
import ProductPackEditDimensions from '@/components/Mobile/ProductPackEditDimensions.vue';
import ProductPackEditNewBarcode from '@/components/Mobile/ProductPackEditNewBarcode.vue';
import ProductPackPrint from '@/components/Mobile/ProductPackPrint.vue';
import NonEditableField from '@/components/NonEditableField.vue';
import ProductPhoto from '@/components/ProductPhoto.vue';
import useDocumentsPrinting from '@/composables/useDocumentsPrinting';
import useErrorHandling from '@/composables/useErrorHandling';
import useLocalizedFormatters from '@/composables/useLocalizedFormatters';
import type {
  Barcode,
  MutationChangeProductPackLastScannedBarcodeArgs,
  MutationDeleteProductPackBarcodeArgs,
  Product,
  ProductPack,
} from '@/graphql/types';
import { PrintBarcodeTypeEnum } from '@/graphql/types';
import capitalize from '@/helpers/capitalize';
import productPackTitle from '@/helpers/productPackTitle';
import useDocumentsPrintingState from '@/views/useDocumentsPrintingState';
import { gql, useMutation } from '@urql/vue';
import * as R from 'ramda';
import { computed, onMounted, reactive, ref } from 'vue';
import { useI18n } from 'vue-i18n';
import ConfirmsAction from '@/components/ConfirmsAction.vue';

const { t } = useI18n();

const { formatDimensions, formatWeight } = useLocalizedFormatters();

// noinspection LocalVariableNamingConventionJS
const { fillErrorsFromGraphQLError, PrimaryErrorBanner } = useErrorHandling();

// noinspection LocalVariableNamingConventionJS
const {
  printNow,
  enqueuePrint,
  selectedPrinter,
  selectedPrinterPaperLayout,
  loadPrinters,
  PrintBtn,
  EnqueueBtn,
} = useDocumentsPrinting();

onMounted(loadPrinters);

const props = defineProps<{
  productPack: ProductPack;
  hideDetails?: boolean;
  hidePhoto?: boolean;
  buttonsPanel?: HTMLElement | string;
}>();

const emit = defineEmits<{
  (e: 'close'): void;
  (e: 'save', value: ProductPack): void;
}>();

const state = useDocumentsPrintingState();

const sortedBarcodes = computed((): Barcode[] => {
  return R.sortBy(
    b => (b.barcode === props.productPack.lastScannedBarcode?.barcode ? 0 : 1),
    props.productPack.barcodes,
  );
});

const product = computed((): Product => {
  return props.productPack.product;
});

const {
  executeMutation: doChangeLastScannedBarcode,
  fetching: changingLastBarcode,
} = useMutation<{ pack: ProductPack }, MutationChangeProductPackLastScannedBarcodeArgs>(
  gql`
    mutation ChangeProductPackLastScannedBarcodeFromCard($productPackId: ID!, $barcode: String!) {
      pack: changeProductPackLastScannedBarcode(productPackId: $productPackId, barcode: $barcode) {
        lastScannedBarcode {
          barcode
          group
          isGenerated
        }
        barcodes {
          barcode
          group
          isGenerated
        }
      }
    }
  `,
);

async function setAsLast(barcode: Barcode) {
  if (barcode.group) {
    return;
  }
  const { data, error } = await doChangeLastScannedBarcode({
    productPackId: props.productPack.id,
    barcode:       barcode.barcode,
  });

  if (error) {
    fillErrorsFromGraphQLError(error);
    return;
  }

  if (data) {
    emit('save', {
      ...props.productPack,
      ...data.pack,
    });
  }
}

const {
  executeMutation: doDeleteBarcode,
} = useMutation<{ pack: ProductPack }, MutationDeleteProductPackBarcodeArgs>(
  gql`
    mutation DeleteProductPackBarcode($productPackId: ID!, $barcode: String!) {
      pack: deleteProductPackBarcode(productPackId: $productPackId, barcode: $barcode) {
        lastScannedBarcode {
          barcode
          group
          isGenerated
        }
        barcodes {
          barcode
          group
          isGenerated
        }
      }
    }
  `,
);

const deletingBarcodes = reactive(new Set<string>());

async function deleteBarcode(barcode: Barcode) {
  deletingBarcodes.add(barcode.barcode);

  const { data } = await doDeleteBarcode({
    productPackId: props.productPack.id,
    barcode:       barcode.barcode,
  });

  deletingBarcodes.delete(barcode.barcode);

  if (data) {
    emit('save', {
      ...props.productPack,
      ...data.pack,
    });
  }
}

const barcodeToPrint = ref<Barcode|null>();
const countToPrint = ref<number>(0);
const packToPrint= ref<ProductPack>();

function handleDataToPrintUpdate(data: {
  barcode: Barcode|null;
  productPack: ProductPack;
  count: number;
}) {
  barcodeToPrint.value = data.barcode;
  packToPrint.value    = data.productPack;
  countToPrint.value   = data.count;
}

async function enqueue(): Promise<void> {
  await enqueuePrint('product-pack-barcode', [{
    count: countToPrint.value,
    params: {
      packId:      packToPrint.value?.id ?? props.productPack.id,
      barcode:     barcodeToPrint.value!.barcode,
      barcodeType: state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE,
    }
  }], selectedPrinterPaperLayout.value!);
}

async function print(): Promise<void> {
  await printNow('product-pack-barcode', [{
    count: countToPrint.value,
    params:{
      packId:      packToPrint.value?.id ?? props.productPack.id,
      barcode:     barcodeToPrint.value!.barcode,
      barcodeType: state.value.lastProductPackBarcodeType ?? PrintBarcodeTypeEnum.BARCODE,
    }
  }], selectedPrinterPaperLayout.value!);
}

function handleDimensionsSaved(payload: Pick<ProductPack, 'dimensions' | 'weight'>) {
  emit('save', {
    ...props.productPack,
    ...payload,
  });
}

</script>

<style scoped lang="scss">

@import "quasar/src/css/core/colors";

:deep(.muted-suffix) .q-field__suffix {
  @extend .text-grey;
}

</style>

<i18n lang="yaml" src="../../plugins/i18n/sharedMessages/printing.yaml"></i18n>

<i18n lang="yaml">
ru:
  Delete Barcode?: Удалить штрихкод?
  New Barcode: Новый штрихкод
  Dimensions: Габариты
en:
  Delete Barcode?: Delete Barcode?
  New Barcode: New Barcode
  Dimensions: Dimensions
</i18n>
