<template>
  <QSelect
    v-bind="$attrs"
    v-model="model"
    :model-value="modelValue"
    :options="options"
    :loading="fetching"
    :error="!!error"
    :error-message="error?.message"
  >
    <template
      v-for="slot in Object.keys($slots)"
      #[slot]="bind"
    >
      <slot
        :name="slot"
        v-bind="bind"
      />
    </template>
  </QSelect>
</template>

<script setup lang="ts">

import { computed, ref } from 'vue';
import { useQuery } from '@urql/vue';
import type { DocumentNode } from 'graphql';
import type { ReportListOptions } from '@/types/reports';
import type { ReportFilterInput } from '@/graphql/types';
import type { Connection } from '@/types';
import { useVModel } from '@vueuse/core';

type TEntity = object;

const props = defineProps<{
  modelValue: TEntity | null;
  limit: number;
  query: DocumentNode;
  filters: ReportFilterInput[];
}>();

const emit = defineEmits<{
  (e: 'update:modelValue', entity: TEntity | null): void;
}>();

const model = useVModel(props, 'modelValue', emit);

const vars = ref<ReportListOptions>({
  first:  props.limit,
  after:  null,
  query:  '',
  filter: props.filters,
});

const { data, fetching, error } = useQuery<{ result: Connection<TEntity> }>({
  query: props.query,
  variables: vars,
});

const options = computed(
  () => (data.value?.result.edges ?? [])
    .map(e => e.node),
);

</script>
