<template>
  <FullHeightPage>
    <GraphQLQueryTable
      :graphql-query="query"
      :fields="tableFields"
      :available-filters="availableFilters"
      :fixed-filters="fixedFilters"
      storage-prefix="inventoriesList.report"
      keep-page-in-url
      sticky-header
      class="col"
    >
      <template #body-cell-id="column">
        <BodyCellLink
          :column="column"
          :to="{ name: ROUTES.INVENTORIES_EDIT, params: { id: column.value } }"
        />
      </template>

      <template #body-cell-state="column">
        <QTd :props="column">
          <QChip
            :color="badgeColors.forOperationState(column.row)"
            text-color="black"
          >
            {{ column.value }}
          </QChip>
        </QTd>
      </template>

      <template #body-cell-difference="column">
        <QTd :props="column">
          <InventoryDifference :inventory="column.row" />
        </QTd>
      </template>

      <template #body-cell-supply="column">
        <QTd :props="column">
          <ReturnableDocumentLabel
            v-if="column.value"
            :document="column.value"
          />
        </QTd>
      </template>

      <template #after-search-string="{ refresh }">
        <InventoryCreateButton @success="refresh" />
      </template>
    </GraphQLQueryTable>
  </FullHeightPage>
</template>

<script setup lang="ts">

import BodyCellLink from '@/components/BaseTable/BodyCellLink.vue';
import FullHeightPage from '@/components/FullHeightPage.vue';
import GraphQLQueryTable from '@/components/GraphQLQueryTable.vue';
import InventoryDifference from '@/components/InventoryDifference.vue';
import ReturnableDocumentLabel from '@/components/ReturnableDocumentLabel.vue';
import useLocalizedFormatters, { FORMATS } from '@/composables/useLocalizedFormatters';
import MovementsForInventory from '@/graphql/fragments/MovementsForInventory';
import type { Inventory, ReportFilterInput, Storage } from '@/graphql/types';
import { InventoryStateEnum } from '@/graphql/types';
import * as badgeColors from '@/helpers/badgeColors';
import * as reports from '@/helpers/reports';
import { createDatesRangeOperator, createPrimitive } from '@/helpers/reports';
import userFullName from '@/helpers/userFullName';
import ROUTES from '@/router/routeNames';
import type { TypenameAware } from '@/types';
import type { ReportFilter, TableColumn } from '@/types/reports';
import InventoryCreateButton from '@/views/Mobile/Inventory/InventoryCreateButton.vue';
import { gql } from '@urql/vue';
import { isNil } from 'ramda';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();

const { formatDate } = useLocalizedFormatters();

const tableFields: TableColumn<TypenameAware<Inventory>>[] = [
  {
    label:     t('ID'),
    name:      'id',
    field:     'id',
    mandatory: true,
    align:     'left',
    sortable:  true,
  },
  {
    label:    t('Created at'),
    name:     'createdAt',
    field:    'createdAt',
    format:   d => formatDate(d, FORMATS.DATETIME),
    align:    'left',
    sortable: true,
  },
  {
    label:  t('User'),
    name:   'user',
    field:  'user',
    format: userFullName,
    align:  'left',
  },
  {
    label: t('State'),
    name:  'state',
    field: 'state',
    align: 'left',
    format: state => t(`inventoryState.${state}`),
  },
  {
    label: t('Difference'),
    name:  'difference',
    field: 'movements',
  },
  {
    label:  t('Storage'),
    name:   'storageName',
    field:  'storage',
    format: (storage?: Storage) => storage?.name ?? '',
  },
  {
    label: t('Inventory Type'),
    name:  'isEntireStorage',
    field: 'isEntireStorage',
    format: entire => isNil(entire) ? '' : (entire ? t('Entire Storage') : t('Some Products')),
  },
  {
    label: t('Supply') + ' / ' + t('Return'),
    name:  'supply',
    field: 'supply',
  },
];

const query = gql`
  query GetInventoriesForReport(
    $page: Int,
    $perPage: Int!,
    $query: String,
    $filter: [ReportFilterInput!],
    $sort: [ReportSortInput!]!,
  ) {
    result: operationsReport(
      page: $page,
      perPage: $perPage,
      query: $query,
      filter: $filter,
      sort: $sort,
    ) {
      data {
        __typename
        id
        createdAt
        user { id firstname lastname }
        ... on Inventory {
          state
          isEntireStorage
          movements { ...MovementForInventory }
          storage { id name }
          supply { id externalId }
        }
      }
      total
    }
  }
  ${MovementsForInventory}
`;

const availableFilters: ReportFilter[] = [
  {
    field:     'createdAt',
    label:     t('Created at'),
    operators: [
      createDatesRangeOperator(),
    ],
  },
  {
    label:     t('Storage'),
    field:     'storageName',
    operators: [
      createPrimitive('=', 'string'),
    ],
  },
  {
    field:     'state',
    label:     t('State'),
    operators: (['=', '!=', 'in', 'not in'] as const).map(op =>
      reports.createList(op, () => Promise.resolve([
        InventoryStateEnum.CREATED,
        InventoryStateEnum.IN_PROGRESS,
        InventoryStateEnum.NEEDS_RECOUNT,
        InventoryStateEnum.COMPLETED,
      ].map(v => ({
        value: v,
        label: t(`inventoryState.${v}`),
      }))), t(`reportFilterOperator.${op}`)),
    ),
  },
];

const fixedFilters: ReportFilterInput[] = [{
  field:    'type',
  operator: '=',
  value:    JSON.stringify('Inventory' as Inventory['__typename']),
}];

</script>

<i18n lang="yaml" src="../../../plugins/i18n/sharedMessages/inventory.yaml"></i18n>

<i18n lang="yaml">
ru:
  Difference: Расхождение
en:
  Difference: Difference
</i18n>
